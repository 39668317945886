import React from 'react'
import * as d3 from 'd3'
import { reshape } from 'mathjs'
import { v4 as uuidv4 } from 'uuid'
import stationInfo from 'static/station_info'
import stationMasks from 'static/station_masks.json'
const _ = require('lodash')

export const EigGraphStateContext = React.createContext()
export const EigGraphDispatchContext = React.createContext()

const m = { t: 10, r: 270, b: 50, l: 60 }
const w = 1000
const h = 465

const ignore_keys = [
  'pin_by_pin_enrichment',
  'pin_by_pin_gad_concentration'
]

const default_ = [
  {
    key: 'eigenvalue',
    axis: 1,
    dominant: true,
    color: 'steelblue',
    rgbaColor: { r: '70', g: '130', b: '180', a: '1' },
    displayColorPicker: false,
    hexColor: '#4682b4',
    x: '',
    y: '',
    z: ''
  },
  {
    key: 'total_core_flow_mlbmhr',
    axis: 3,
    dominant: true,
    color: 'brown',
    rgbaColor: { r: '165', g: '42', b: '42', a: '1' },
    displayColorPicker: false,
    hexColor: '#964B00',
    x: '',
    y: '',
    z: ''
  },
  {
    key: 'total_core_power_mwth',
    axis: 4,
    dominant: true,
    color: 'goldenrod',
    rgbaColor: { r: '218', g: '165', b: '32', a: '1' },
    displayColorPicker: false,
    hexColor: '#daa520',
    x: '',
    y: '',
    z: ''
  }
]

const initialState = {
  lines: default_,
  renderLines: default_
}

function reducer (state, action) {
  const i = action.index
  console.log('action', action.type, i, action, state)
  switch (action.type) {
    case 'addLine': {
      if (state.lines.length > 7) {
        break
      }
      const dIndex = (
        (state.lines.length < default_.length) 
          ? state.lines.length
          : 0
      )
      state.lines.push({...default_[dIndex]})
      break
    }
    case 'toggleColorPicker': {
      state.lines[i].displayColorPicker = !state.lines[i].displayColorPicker
      break
    }
    case 'toggleDominant': {
      state.lines[i].dominant = !state.lines[i].dominant
      break
    }
    case 'updateAxis': {
      state.lines[i].axis = action.value
      break
    }
    case 'updateColorChoice': {
      state.lines[i].hexColor = action.color.hex
      state.lines[i].rgbaColor = action.color.rgb
      state.lines[i].displayColorPicker = false
      break
    }
    case 'updateCoordinate': {
      const value = action.value
      const empty = (value.length === 0)
      const parseable = (!!parseInt(value))
      const inRange = value > 0 && value <= state.shape[action.axis]
      if (empty) {
        state.lines[i][action.axis] = ''
      }
      else if (parseable && inRange){
        state.lines[i][action.axis] = action.value
      }
      break
    }
    case 'updateKey': {
      state.lines[i].key = action.value
      break
    }
    case 'removeLine': {
      if (state.lines.length > 1) {
        state.lines.pop()
      }
      break
    }
    case 'loadHdf': {
      state.hdf = action.hdf
      state.station = action.station
      state.dispatch = action.dispatch
      const msk = stationMasks[stationInfo[state.station].reactor_mask]
      const size = msk.length
      const width = Math.sqrt(size)
      state.mask = reshape(msk, [width, width])
      state.fileState = { keys: {} }
      let exposureMax = 0
      let exposureMin = 999999
      state.hdf.keys.filter(x => !ignore_keys.includes(x))
        .forEach(pk => {
          console.log(pk)
          const point = state.hdf.get(pk)
          point.keys.forEach(vk => {
            console.log(vk)
            if (!(vk in state.fileState.keys)) {
              state.fileState.keys[vk] = { dim: point.get(vk).shape.length }
            }
            exposureMin = Math.min(
              exposureMin,
              point.get('core_average_exposure_mwdst').value
            )
            exposureMax = Math.max(
              exposureMax,
              point.get('core_average_exposure_mwdst').value
            )
          })
        })
      console.log(state.fileState)
      const xscale = d3.scaleLinear()
        .domain([exposureMin, exposureMax])
        .range([m.l + 50, w - m.r - 50])
      state.xscale = xscale.copy()
      state.xreset = xscale.copy()
      let oldRods = null
      let newRods = null
      const thisData = []
      const first_point = state.hdf.keys[0]
      const shape = state.hdf.get(first_point).get('nodal_relative_power').shape
      state.shape = {
        'x': shape[2],
        'y': shape[1],
        'z': shape[0]
      }
      state.hdf.keys.filter(x => !ignore_keys.includes(x))
        .forEach(pk => {
        const pt = state.hdf.get(pk)
        const newPt = {}
        newPt.CYEXP = parseFloat(pt.get('core_average_exposure_mwdst').value)
        state.lines.forEach(l => {
          switch (state.fileState.keys[l.key].dim) {
            case 0: {
              const res = parseFloat(pt.get(l.key).value)
              if (res > 0) { newPt[l.key] = res }
              break
            }
            case 1: {
              newPt[l.key] = parseFloat(pt.get(l.key).value[l.z])
              break
            }
            case 2: {
              const width = pt.get(l.key).shape[0]
              newPt[l.key] = parseFloat(pt.get(l.key).value[width * l.y + l.x])
              break
            }
            case 3: {
              const value = reshape(pt.get(l.key).value, pt.get(l.key).shape)[l.z][l.y][l.x]
              const parsed = parseFloat(value)
              newPt[l.key] = parsed
              break
            }
          }
        }) 
        newRods = pt.get('rod_pattern').value
        if (!_.isEqual(oldRods, newRods)) { newPt.rods = newRods }
        thisData.push(newPt)
        oldRods = newRods
      })
      state.data = thisData
      state.scales = []
      for (const i of [1, 2, 3, 4]) {
        console.log('setting scale for axis ' + i.toString())
        const axisLines = state.lines.filter(l => l.axis === i)
        const domFilter = axisLines.filter(l => l.dominant)
        console.log('dom:')
        if (domFilter.length > 1) {
          console.log('too many dominant lines!')
        } else if (domFilter.length === 1) {
          console.log('dominant line')
          const line = domFilter[0]
          if (line.key === 'eigenvalue') {
            state.scales[i] = d3.scaleLinear()
              .domain([
                d3.min(state.data, d => parseFloat(d[line.key])) - .001,
                d3.max(state.data, d => parseFloat(d[line.key])) + .001
              ])
              .range([h - m.b, m.t])
          } else {
            state.scales[i] = d3.scaleLinear()
              .domain([0, d3.max(state.data, d => parseFloat(d[line.key]))])
              .range([h - m.b, m.t])
          }
        } else {
          console.log('no dominant, taking overall max')
          const maxes = axisLines
            .map(l => d3.max(state.data, d => parseFloat(d[l.key])))
          const max = Math.max(...maxes)
          state.scales[i] = d3.scaleLinear()
            .domain([0, max])
            .range([h - m.b, m.t])
        }
      }
      state.renderLines = JSON.parse(JSON.stringify(state.lines))
      break
    }
    case 'loadData': {
      let oldRods = null
      let newRods = null
      const thisData = []
      state.hdf.keys.filter(x => !ignore_keys.includes(x))
        .forEach(pk => {
        const pt = state.hdf.get(pk)
        const newPt = {}
        newPt.CYEXP = parseFloat(pt.get('core_average_exposure_mwdst').value)
        state.lines.forEach(l => {
          switch (state.fileState.keys[l.key].dim) {
            case 0: {
              const res = parseFloat(pt.get(l.key).value)
              if (res > 0) { newPt[l.key] = res }
              break
            }
            case 1: {
              newPt[l.key] = parseFloat(pt.get(l.key).value[l.z])
              break
            }
            case 2: {
              const width = pt.get(l.key).shape[0]
              newPt[l.key] = parseFloat(pt.get(l.key).value[width * l.y + l.x])
              break
            }
            case 3: {
              const value = reshape(pt.get(l.key).value, pt.get(l.key).shape)[l.z][l.y][l.x]
              const parsed = parseFloat(value)
              newPt[l.key] = parsed
              break
            }
          }
        }) 
        newRods = pt.get('rod_pattern').value
        if (!_.isEqual(oldRods, newRods)) { newPt.rods = newRods }
        thisData.push(newPt)
        oldRods = newRods
      })
      state.data = thisData
      break
    }
    case 'resetZoom': {
      if (!state.xreset) {
        break
      }
      state.xscale = state.xreset.copy()
      break
    }
    case 'updateZoom': {
      if (!state.xscale) {
        break
      }
      state.xscale = d3.scaleLinear().domain([
          state.xscale.invert(action.extent[0]),
          state.xscale.invert(action.extent[1])
      ]).range([m.l + 50, w - m.r - 50])
      break
    }
    case 'submit': {
      let cancel = false
      state.lines.forEach(l => {
        const dim = state.fileState.keys[l.key].dim
        if ([2, 3].includes(dim)) {
          if (state.mask[l.y - 1][parseInt(l.x) - 1] === 0) {
            state.dispatch({
              type: 'addAlert',
              toAdd: {
                id: uuidv4(),
                type: 'text',
                text: 'Selected coordinate is outside of station mask'
              }
            })
            cancel = true
          }
        }
      })
      for (const i of [1, 2, 3, 4]) {
        const axisLines = state.lines.filter(l => l.axis === i)
        const domFilter = axisLines.filter(l => l.dominant)
        if (domFilter.length > 1) {
            state.dispatch({
              type: 'addAlert',
              toAdd: {
                id: uuidv4(),
                type: 'text',
                text: 'Too many dominant lines'
              }
            })
            cancel = true
        }
      }
      if (cancel) {
        break
      }
      let oldRods = null
      let newRods = null
      const thisData = []
      state.hdf.keys.filter(x => !ignore_keys.includes(x))
        .forEach(pk => {
        const pt = state.hdf.get(pk)
        const newPt = {}
        newPt.CYEXP = parseFloat(pt.get('core_average_exposure_mwdst').value)
        state.lines.forEach(l => {
          switch (state.fileState.keys[l.key].dim) {
            case 0: {
              const res = parseFloat(pt.get(l.key).value)
              if (res > 0) { newPt[l.key] = res }
              break
            }
            case 1: {
              const index = parseInt(l.z)
              newPt[l.key] = parseFloat(pt.get(l.key).value[index])
              break
            }
            case 2: { 
              const width = pt.get(l.key).shape[0]
              const index = width * parseInt(l.y) + parseInt(l.x)
              newPt[l.key] = parseFloat(pt.get(l.key).value[index])
              break
            }
            case 3: {
              const value = reshape(pt.get(l.key).value, pt.get(l.key).shape)[l.z][l.y][l.x]
              const parsed = parseFloat(value)
              newPt[l.key] = parsed
              break
            }
          }
        }) 
        newRods = pt.get('rod_pattern').value
        if (!_.isEqual(oldRods, newRods)) {
            newRods = pt.get('rod_pattern').value
            newPt.rods = newRods
        }
        thisData.push(newPt)
        oldRods = newRods
      })
      state.data = thisData
      state.scales = []
      for (const i of [1, 2, 3, 4]) {
        console.log('setting scale for axis ' + i.toString())
        console.log('lines', state.lines)
        const axisLines = state.lines.filter(l => l.axis === i)
        console.log('axisLines', axisLines)
        const domFilter = axisLines.filter(l => l.dominant)
        console.log('domFilter', domFilter)
        console.log('dom:')
        if (domFilter.length > 1) {
          console.log('too many dominant lines!')
        } else if (domFilter.length === 1) {
          console.log('dominant line')
          const line = domFilter[0]
          if (line.key === 'eigenvalue') {
            state.scales[i] = d3.scaleLinear()
              .domain([
                d3.min(state.data, d => parseFloat(d[line.key])) - .001,
                d3.max(state.data, d => parseFloat(d[line.key])) + .001
              ])
              .range([h - m.b, m.t])
          } else {
            state.scales[i] = d3.scaleLinear()
              .domain([0, d3.max(state.data, d => parseFloat(d[line.key]))])
              .range([h - m.b, m.t])
          }
        } else {
          console.log('no dominant, taking overall max')
          const maxes = axisLines
            .map(l => d3.max(state.data, d => parseFloat(d[l.key])))
          console.log('maxes', maxes)
          const max = Math.max(...maxes)
          state.scales[i] = d3.scaleLinear()
            .domain([0, max])
            .range([h - m.b, m.t])
        }
      }
      state.renderLines = JSON.parse(JSON.stringify(state.lines))
      break
    } 
  }
  console.log(state)
  return {...state}
}

const EigGraphContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <EigGraphStateContext.Provider value={state}>
      <EigGraphDispatchContext.Provider value={dispatch}>
        {children}
      </EigGraphDispatchContext.Provider>
    </EigGraphStateContext.Provider>
  )
}

export default EigGraphContextProvider